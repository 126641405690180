import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import Layout from 'components/common/layout/layout';
import { SEO } from 'components/SEO/SEO';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};

const MDXLayout = ({
  children
}) => <Layout>
        <SEO title=" Política de cookies | PCIs RAMÓN Y CAJAL ZARAGOZA " description="Política de cookies de  PCIs RAMÓN Y CAJAL ZARAGOZA " robots="noindex nofollow" />
        <div className="docs">
            {children}
        </div>
    </Layout>;

export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Política de cookies`}</h1>
    <p><strong parentName="p">{`INFORMACIÓN SOBRE COOKIES`}</strong></p>
    <p>{`Conforme con la Ley 34/2002, de 11 de julio, de servicios de la sociedad de la información y de comercio electrónico (LSSI), en relación con el Reglamento (UE) 2016/679 del Parlamento Europeo y del Consejo, de 27de abril de 2016, General de Protección de Datos (GDPR) y la Ley Orgánica 3/2018, de 5 de diciembre, deProtección de Datos y Garantía de los Derechos Digitales (LOPDGDD), es obligado obtener el consentimiento expreso del usuario de todas las páginas web que usan cookies prescindibles, antes de que este navegue por ellas.`}</p>
    <p><strong parentName="p">{`¿QUÉ SON LAS COOKIES?`}</strong></p>
    <p>{`Las cookies y otras tecnologías similares tales como local shared objects, flash cookies o píxeles, son herramientas empleadas por los servidores Web para almacenar y recuperar información acerca de sus visitantes, así como para ofrecer un correcto funcionamiento del sitio.`}</p>
    <p>{`Mediante el uso de estos dispositivos se permite al servidor Web recordar algunos datos concernientes al usuario, como sus preferencias para la visualización de las páginas de ese servidor, nombre y contraseña, productos que más le interesan, etc.`}</p>
    <p><strong parentName="p">{`COOKIES AFECTADAS POR LA NORMATIVA Y COOKIES EXCEPTUADAS`}</strong></p>
    <p>{`Según la directiva de la UE, las cookies que requieren el consentimiento informado por parte del usuario son las cookies de analítica y las de publicidad y afiliación, quedando exceptuadas las de carácter técnico y las necesarias para el funcionamiento del sitio web o la prestación de servicios expresamente solicitados por el usuario.`}</p>
    <p><strong parentName="p">{`TIPOS DE COOKIES`}</strong></p>
    <p><strong parentName="p">{`SEGÚN LA FINALIDAD`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Cookies técnicas y funcionales: son aquellas que permiten al usuario la navegación a través de una página web, plataforma o aplicación y la utilización de las diferentes opciones o servicios que en ella existan.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies analíticas: son aquellas que permiten al responsable de las mismas el seguimiento y análisis del comportamiento de los usuarios de los sitios web a los que están vinculadas. La información recogida mediante este tipo de cookies se utiliza en la medición de la actividad de los sitios web, aplicación o plataforma y para la elaboración de perfiles de navegación de los usuarios de dichos sitios, aplicaciones y plataformas, con el fin de introducir mejoras en función del análisis de los datos de uso que hacen los usuarios del servicio.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies publicitarias: son aquellas que permiten la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado en base a criterios como el contenido editado o la frecuencia en la que se muestran los anuncios.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies de publicidad comportamental: recogen información sobre las preferencias y elecciones personales del usuario (retargeting) para permitir la gestión, de la forma más eficaz posible, de los espacios publicitarios que, en su caso, el editor haya incluido en una página web, aplicación o plataforma desde la que presta el servicio solicitado.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies sociales: son establecidas por las plataformas de redes sociales en los servicios para permitirle compartir contenido con sus amigos y redes. Las plataformas de medios sociales tienen la capacidad de rastrear su actividad en línea fuera de los Servicios. Esto puede afectar al contenido y los mensajes que ve en otros servicios que visita.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies de afiliados: permiten hacer un seguimiento de las visitas procedentes de otras webs, con lasque el sitio web establece un contrato de afiliación (empresas de afiliación).`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies de seguridad: almacenan información cifrada para evitar que los datos guardados en ellas sean vulnerables a ataques maliciosos de terceros.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`SEGÚN LA PROPIEDAD`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Cookies propias: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio gestionado por el propio editor y desde el que se presta el servicio solicitado por el usuario.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies de terceros: son aquellas que se envían al equipo terminal del usuario desde un equipo o dominio que no es gestionado por el editor, sino por otra entidad que trata los datos obtenidos través delas cookies.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`SEGÚN EL PLAZO DE CONSERVACIÓN`}</strong></p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Cookies de sesión: son un tipo de cookies diseñadas para recabar y almacenar datos mientras el usuario accede a una página web.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Cookies persistentes: son un tipo de cookies en el que los datos siguen almacenados en el terminal y pueden ser accedidos y tratados durante un período definido por el responsable de la cookie, y que puede ir de unos minutos a varios años.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`TRATAMIENTO DE DATOS PERSONALES`}</strong></p>
    <p>{`IES Ramón y Cajal es el Responsable del tratamiento de los datos personales del Interesado y le informa de que estos datos serán tratados de conformidad con lo dispuesto en el Reglamento (UE) 2016/679, de 27 de abril de 2016 (GDPR), por lo que se le facilita la siguiente información del tratamiento:`}</p>
    <p>{`Fines del tratamiento: según se especifica en el apartado de cookies que se utilizan en este sitio web.`}</p>
    <p>{`Legitimación del tratamiento: salvo en los casos en los que resulte necesario para la navegación por la web, por consentimiento del interesado (art. 6.1 GDPR).`}</p>
    <p>{`Criterios de conservación de los datos: según se especifica en el apartado de cookies utilizadas en la web.`}</p>
    <p>{`Comunicación de los datos: no se comunicarán los datos a terceros, excepto en cookies propiedad de terceros o por obligación legal.`}</p>
    <p>{`Derechos que asisten al Interesado:`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Derecho a retirar el consentimiento en cualquier momento.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Derecho de acceso, rectificación, portabilidad y supresión de sus datos, y de limitación u oposición a su tratamiento.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Derecho a presentar una reclamación ante la Autoridad de control (`}<a parentName="p" {...{
            "href": "http://www.aepd.es"
          }}>{`www.aepd.es`}</a>{`) si considera que el tratamiento no se ajusta a la normativa vigente.`}</p>
      </li>
    </ul>
    <p>{`Datos de contacto para ejercer sus derechos:`}</p>
    <p>{`IES Ramón y Cajal,  C/ Ramón Pignatelli, 102, 50004 Zaragoza. E-mail: `}<a parentName="p" {...{
        "href": "mailto:pci.ryc.zgz@gmail.com"
      }}>{`pci.ryc.zgz@gmail.com`}</a></p>
    <p><strong parentName="p">{`COOKIES UTILIZADAS EN ESTE SITIO WEB`}</strong></p>
    <p><strong parentName="p">{`COOKIES CONTROLADAS POR EL EDITOR`}</strong></p>
    <p><strong parentName="p">{`Analíticas`}</strong></p>
    <p>{`Cookies de Google Analytics:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Temporalidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Titularidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Finalidad`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`_`}{`ga, `}{`_`}{`ga_*, _gat_UA_ID, _gid, Otras posibles cookies a cargar por el servicio de monitorización de visitas de Google `}{`[1]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanente / Temporal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ajena. Condiciones del servicio: `}{`[2]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Se utilizan para generar un identificador de usuario único (no se envía IP), utilizado para hacer recuento de cuántas veces visita el sitio un usuario, así como la fecha de la primera y la última vez que visitó la web. Registran la fecha y hora de acceso a cualquiera de las páginas del portal. Comprueban la necesidad de mantener la sesión de un usuario abierta o crear una nueva. Identifican la sesión del usuario, para recoger la ubicación geográfica aproximada del ordenador que accede al portal.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`[1]`}{` – Uso de las cookies de Google Analytics en los sitios web: `}<a parentName="p" {...{
        "href": "https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es"
      }}>{`https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=es`}</a></p>
    <p>{`[2]`}{` – Condiciones de privacidad de Google Analytics: `}<a parentName="p" {...{
        "href": "https://www.google.com/policies/privacy/"
      }}>{`https://www.google.com/policies/privacy/`}</a></p>
    <p><strong parentName="p">{`Técnicas`}</strong></p>
    <p>{`Cookies propias del aviso de  gestión de cookies:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Temporalidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Titularidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Finalidad`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`cc_cookie`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Temporal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Propia`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Se utilizan para el control de aparición y aceptación del propio aviso de gestión de cookies.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`Cookies de Google:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Temporalidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Titularidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Finalidad`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`CONSENT, SOCS, NID, AEC, Otras posibles cookies a cargar por los servicios de Google `}{`[3]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Permanente / Temporal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ajena`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Se utilizan para el funcionamiento de servicios de Google, servicios de búsqueda y compartición de contenidos.`}</td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`Social`}</strong></p>
    <p>{`Cookies de Youtube:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Cookies`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Temporalidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Titularidad`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Finalidad`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`YouTube`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Temporal`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Ajena. Condiciones del servicio: `}{`[3]`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Se utilizan para registrar las preferencias del reproductor de vídeo del usuario al ver vídeos incrustados de YouTube.`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`[3]`}{` – Condiciones de las cookies de Google y de YouTube: `}<a parentName="p" {...{
        "href": "http://www.google.com/policies/technologies/types/"
      }}>{`http://www.google.com/policies/technologies/types/`}</a></p>
    <p><strong parentName="p">{`COOKIES DE TERCEROS`}</strong></p>
    <p>{`Los servicios de terceros son ajenos al control del editor. Los proveedores pueden modificar en todo momento sus condiciones de servicio, finalidad y utilización de las cookies, etc.`}</p>
    <p>{`Proveedores externos de este sitio web:`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Editor`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Política de privacidad`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Google`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://privacy.google.com/take-control.html"
            }}>{`https://privacy.google.com/take-control.html`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Google`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://safety.google/privacy/privacy-controls/"
            }}>{`https://safety.google/privacy/privacy-controls/`}</a></td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`Youtube`}</td>
          <td parentName="tr" {...{
            "align": null
          }}><a parentName="td" {...{
              "href": "https://privacy.google.com/take-control.html"
            }}>{`https://privacy.google.com/take-control.html`}</a></td>
        </tr>
      </tbody>
    </table>
    <p><strong parentName="p">{`CÓMO MODIFICAR LA CONFIGURACIÓN DE LAS COOKIES`}</strong></p>
    <p>{`Gestionar cookies. Mediante el banner de opciones en la gestión de cookies se pueden seleccionar las cookies que se desean aceptar o rechazar.`}</p>
    <p><strong parentName="p">{`DESACTIVAR LAS COOKIES`}</strong></p>
    <p>{`Puede usted permitir, bloquear o eliminar las cookies instaladas en su equipo mediante la configuración de las opciones del navegador instalado en su ordenador.
En la mayoría de los navegadores web se ofrece la posibilidad de permitir, bloquear o eliminar las cookies instaladas en su equipo.
A continuación puede acceder a la configuración de los navegadores webs más frecuentes para aceptar, instalar o desactivar las cookies:`}</p>
    <p><a parentName="p" {...{
        "href": "https://support.google.com/chrome/answer/95647?hl=es"
      }}>{`Configurar cookies en Google Chrome`}</a></p>
    <p><a parentName="p" {...{
        "href": "http://windows.microsoft.com/es-es/windows7/how-to-manage-cookies-in-internet-explorer-9"
      }}>{`Configurar cookies en Microsoft Internet Explorer`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.mozilla.org/es/kb/habilitar-y-deshabilitar-cookies-sitios-web-rastrear-preferencias?redirectlocale=es&redirectslug=habilitar-y-deshabilitar-cookies-que-los-sitios-we"
      }}>{`Configurar cookies en Mozilla Firefox`}</a></p>
    <p><a parentName="p" {...{
        "href": "https://support.apple.com/es-es/HT201265"
      }}>{`Configurar cookies en Safari (Apple)`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      